<template>
  <el-dialog
      title="候选人资格审查--批量"
      :visible.sync="show"
      class="checkCandidate-checklot"
      :append-to-body="true"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      custom-class
      width="80%"
      :before-close="beforeClose"
  >
    <el-table
        height="95%"
        fit
        align="center"
        ref="table"
        v-loading="table.loading"
        :data="table.data"
        default-expand-all
        row-key="id"
        :header-cell-style="{'text-align':'center','background-color':'#FAFAFA'}"
        :cell-style="{'text-align':'center'}"
        @selection-change="handleSelectionChange"
        @cell-click="selectOrCancle"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column type="index" label="序号" :index="indexMath"></el-table-column>
      <el-table-column prop="nd" label="年度"></el-table-column>
      <el-table-column prop="sfzhm" label="身份证号码" width="180"></el-table-column>
      <el-table-column prop="xm" label="姓名"></el-table-column>
      <el-table-column prop="xb" label="性别" width="50"></el-table-column>
      <el-table-column prop="bmfs_a" label="报名方式"></el-table-column>
      <el-table-column prop="csrq" label="出生日期">
        <template slot-scope="scope">
          <span v-if="scope.row.csrq">{{ scope.row.csrq }}</span>
          <span v-else>{{ scope.row.sfzhm|csrq }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="lxfs" label="联系方式" width="120"></el-table-column>
      <el-table-column prop="cjzd" label="常住地址" width="300"></el-table-column>
      <el-table-column prop="sczt_a" label="审查状态" width="120"></el-table-column>
    </el-table>
    <div class="footer">
      <el-button type="primary" size="small" @click="pass" :loading="pass_loading">批量通过</el-button>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="table.query.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="table.query.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="table.total"
      ></el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import numberToString from '@/plugin/numberToString';
import Candidate from '@/api/choose/candidate';

export default {
  name: 'checklot',
  props: {
    bmfs: {
      type: String,
      default: () => '1'
    }
  },
  components: {},
  data() {
    return {
      show: false,
      table: {
        data: [],
        query: {
          keyword: '',
          page: 1,
          size: 100,
          nd: new Date().getFullYear().toString(), //年度
          bmfs: '1', //报名方式  1-随机抽选|2-个人申请|3-组织推荐
          sczt: '1,2,3,5', //审查状态，多个状态用英文逗号隔开
          fpdw: '', //分配单位ID，n为未分配
        },
        loading: false,
        total: 0,
        options: {
          sczt: {
            children: [],
          },
          bmfs: {
            children: [],
          },
        },
        selected: [],
      },
      pass_loading: false,
    };
  },
  methods: {
    beforeClose() {
      return this.toggle(false);
    },
    indexMath(index) {
      return index + (this.table.query.page - 1) * this.table.query.size + 1;
    },
    // 切换单元格的选中状态
    selectOrCancle(data) {
      this.$refs.table.toggleRowSelection(data);
    },
    // 批量通过
    pass() {
      this.pass_loading = true;
      let sfzhm = this.table.selected.map((item) => {
        return item.sfzhm;
      });
      if (sfzhm.length === 0) {
        this.$alert('请选择要通过审核的候选人');
        this.pass_loading = false;
        return;
      }
      Candidate.check({
        ok: 1,
        sfzhm,
      })
          .then(() => {
            this.$message.closeAll();
            this.$message.success('批量审核通过成功');
            return this.reset();
          })
          .finally(() => {
            this.pass_loading = false;
          });
    },
    toggle(show) {
      console.log(show)
      if (!show) {
        this.$emit('getList');
        this.show = false;
        this.$emit('changeShow', false);
      } else {
        this.show = true;
        return this.getList();
      }
    },
    handleSelectionChange(data) {
      this.table.selected = data;
      console.log(this.table.selected);
    },
    handleSizeChange(data) {
      this.table.query.size = data;
      this.page = 1;
      return this.getList();
    },
    // 当前页数变化
    handleCurrentChange(data) {
      if (data === this.table.query.page) {
        return;
      } else {
        this.table.query.page = data;
        return this.getList();
      }
    },
    reset() {
      this.table.query.page = 1;
      this.table.query.size = 100;
      this.table.query.keyword = '';
      this.table.query.sczt = '1,2,3,5';
      this.table.query.nd = new Date().getFullYear().toString();
      return this.getList();
    },
    getList() {
      this.table.loading = true;
      console.log(this.table)
      this.table.query.bmfs = this.$props.bmfs
      Candidate.list(this.table.query)
          .then((res) => {
            // 将审查状态由数字 变为可读的中文
            let data = numberToString(res.items, 'sczt', this.options.sczt.children);
            data = numberToString(res.items, 'bmfs', this.options.bmfs.children);
            this.table.data = data;
            this.table.total = res.total;
          })
          .finally(() => {
            this.table.loading = false;
          });
    },
  },
  mounted() {
    // 设置屏幕高度90%
  },
  created() {
    let {bmfs, sczt} = this.$store.getters.dic;
    this.options = {bmfs, sczt};
    return this.getList();
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.checkCandidate-checklot {
  .footer {
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .el-pagination {
      padding: 10px;
      box-sizing: border-box;
      width: 70%;
    }

    .el-button {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-right: 50px;
    }
  }
}

::v-deep .el-dialog {
  margin: 5vh auto 0 auto !important;
}

::v-deep .el-dialog__body {
  height: 80vh;
}
</style>
